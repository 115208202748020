import request from '@/axios/request';
import qs from "qs";

export function login(data) {
    return request({
      url: '/api/user/login',
      method: 'post',
      data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
    });
}
export function addUser(data) {
  return request({
    url: '/api/user/addUser',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}

export function addMhUser(data) {
  return request({
    url: '/api/user/addMhUser',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}

export function getMhUser(data) {
  return request({
    url: '/api/user/getMhUser',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}

export function deleteMhUser(data) {
  return request({
    url: '/api/user/deleteMhUser',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}
